@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.section {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in {
  opacity: 1 !important;
}

html, body {
  scroll-behavior: smooth;
  height: calc(var(--vh, 1vh) * 100);
  scroll-snap-type: y mandatory;
}

@supports (-webkit-overflow-scrolling: touch) {
  html, body {
    overflow-x: hidden;
    overscroll-behavior: contain;
  }
}

.section {
  scroll-snap-align: start;
  height: 100vh;
  scroll-snap-stop: always;
}

#projects {
  scroll-snap-align: start;
  height: 180vh;
}

#about {
  scroll-snap-align: start;
}

@supports (-moz-appearance: none) {
  html, body {
    scroll-snap-type: none !important;
  }  
}

#projects {
  scroll-snap-align: start;
  height: 180vh;
}

@media (max-height: 1400px) and (min-height: 1025px) {
  #projects {
    scroll-snap-align: start;
    height: 200vh;
  }
}

@media (max-height: 1024px) and (min-height: 901px) {
  #projects {
    scroll-snap-align: start;
    height: 250vh;
  }
}

@media (max-height: 900px) and (min-height: 801px) {
  #projects {
    scroll-snap-align: start;
    height: 350vh;
  }
}

@media (max-height: 800px) and (min-height: 701px) {
  #projects {
    scroll-snap-align: start;
    height: 300vh;
  }
}

@media (max-height: 700px) and (min-height: 601px) {
  #projects {
    scroll-snap-align: start;
    height: 350vh;
  }
}

@media (max-height: 600px) and (min-height: 501px) {
  #projects {
    scroll-snap-align: start;
    height: 400vh;
  }
}

@media (max-height: 500px) and (min-height: 401px) {
  #projects {
    scroll-snap-align: start;
    height: 450vh;
  }
}

@media (max-height: 400px) and (min-height: 201px) {
  #projects {
    scroll-snap-align: start;
    height: 800vh;
  }
}

@media (max-height: 200px) {
  #projects {
    scroll-snap-align: start;
    height: 1500vh;
  }
}

/* Apply only on mobile screens */
@media (max-width: 1024px) {
  html, body {
    scroll-snap-type: none;
  }

  #projects {
    scroll-snap-align: start;
    height: 250vh;
  }

  #about {
    height: 125vh;
  }

  .project-card {
    background: transparent;
  }

  .project-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.4;
    z-index: -1;
  }

  /* Move everything further upwards on mobile */
  #home {
    margin-top: -272px;
    min-height: 120vh;
  }

  /* Move everything further upwards on mobile */
  .floating-icons {
    bottom: 21% !important;
  }  
  
  nav a {
    padding: 8px 16px;
  }

  /* Specific adjustments for landscape */
  @media (orientation: landscape) {
    .floating-icons {
      bottom: 5%; /* Adjust the bottom positioning */
      -webkit-transform: translateY(0); /* Ensure webkit handles positioning */
      transform: translateY(0);
    }

    #projects {
      min-height: 100vh;
      display: block;
      overflow-y: auto; /* Enable scrolling */
    }

    #home {
      margin-top: 0; /* Reset margins in landscape */
    }
  }
}


.floating-icons {
  position: absolute;
  bottom: 15%;
  display: flex;
  gap: 20px;
}

.bubble {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: white;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.bubble:hover {
  transform: scale(1.2);
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.6);
}

.bubble:nth-child(1) { animation: float 5s ease-in-out infinite; }
.bubble:nth-child(2) { animation: float 7s ease-in-out infinite; }
.bubble:nth-child(3) { animation: float 6s ease-in-out infinite; }
.bubble:nth-child(4) { animation: float 4.5s ease-in-out infinite; }

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}
